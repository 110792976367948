@import "../../styles/helpers";

.iconDivContainer {
    // display: flex;
    // flex: 1;
    // height: 100%;
    // width: 100%;
    // align-items: center;
    // justify-content: center;
}

.iconDivOuter {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.iconDivMiddle {
    display: table-cell;
    vertical-align: middle;
}

.iconDivInner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15%;
    padding-right: 15%;
}

.referralCode {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    overflow-wrap: break-word;
}

.referralLink {
    overflow-wrap: break-word; 
}

.copyButton {
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  border-radius: 4px;
  color: #3c4043;
  cursor: pointer;
  font-family: arial,sans-serif;
  font-size: 14px;
  height: 36px;
  line-height: 27px;
  min-width: 54px;
  padding: 0 16px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
}

.copyButton:hover {
  border-color: #dadce0;
  box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
  color: #202124;
}

// .copyButton:focus {
//   border-color: #4285f4;
//   outline: none;
// }